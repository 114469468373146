.searchInput {
  width: 95%;
  max-height: 3rem;
  background-color: white;
  display: flex;
  padding: 0.1rem 0 !important;
  margin: 1rem auto;
  border-radius: 5px;
}

.searchIcon {
  width: 10%;
  padding: 0.5rem;
}

.searchIcon svg {
  width: 100%;
  height: 100%;
}

.searchBox {
  width: 90%;
}

.searchBox form {
  width: 100%;
  height: 100%;
  border-width: 0;
  padding-right: 1rem;
}

.searchBox input {
  width: 100%;
  height: 100%;
  border-width: 0;
  padding: 0 0.5rem;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  font-size: 16px;
  font-weight: bold;
  color: rgb(61, 61, 61);
  background-color: var(--white);
}
