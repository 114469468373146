.drivingContainer {
  background-color: white;
  padding-bottom: 10rem;
}

.header {
  position: relative;
}

.drivingBanner {
  width: 100%;
}

.introContainerTop {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.contactButtonTop {
  text-decoration: none;
  /* background-color: rgba(4, 172, 158, 0.226); */
  background-color: white;
  color: var(--primary);
  border-radius: 5px;
  padding: 0.3rem 1rem;
  margin: 0 1rem;
  width: 7rem;
  text-align: center;
  font-weight: bold;
}

.introContainer {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.contactButton {
  text-decoration: none;
  background-color: rgba(4, 172, 158, 0.226);
  color: var(--primary);
  border-radius: 5px;
  padding: 0.3rem 1rem;
  margin: 0 1rem;
  width: 7rem;
  text-align: center;
  font-weight: bold;
}

/* .introContainer a:nth-child(2) {
  background-color: var(--primary);
  color: var(--white);
} */

.bodyContainer h3 {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 2rem;
}

.bodyContainer h5 {
  text-align: center;
}

.bodyContainer div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.numberIcon {
  width: 3rem;
  margin: 1.5rem 0 1rem 0;
}

.bodyContainer ul {
  list-style: none;
  padding: 0;
}

.bodyContainer ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payIcon {
  width: 5rem;
  padding: 0;
  margin: 0;
}

.payText {
  margin: 1.5rem 0 0 0;
  padding: 0;
  font-weight: bold;
}

.signupContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.signupButton {
  text-decoration: none;
  color: rgba(4, 172, 158, 0.226);
  border: 1px solid rgba(4, 172, 158, 0.226);
  color: var(--primary);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin: 1rem;
  text-align: center;
  font-weight: bold;
  background-color: var(--white);
}

.helpText {
  font-weight: bold;
  margin: 3rem 0 0 0;
  text-align: center;
}

.mobileMoneyContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
