.container {
  display: flex;
  height: 100vh;
}

.pageTitle {
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 2.5rem;
}

.navItems {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navItem {
  display: flex;
  cursor: pointer;
  width: 100%;
}

.navItem p {
  width: 100%;
  text-align: center;
  background-color: rgb(65, 179, 154);
  color: var(--white);
  font-weight: bold;
  margin: 0.2rem;
  padding: 0.7rem 0;
  border-radius: 5px;
}

.leftNav {
  width: 17%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
}

.mainBoard {
  width: 100%;
  height: 100vh;
  overflow: scroll;
}
