.container {
  width: 95%;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formLabel {
  margin: 1rem 0 0.7rem;
  text-align: center;
}

.formInput {
  padding: 0.5rem;
}

.formButton {
  margin: 1rem 0;
  background-color: var(--primary);
  border: 0;
  color: white;
  font-weight: bold;
  padding: 0.5rem;
  text-align: center;
}
