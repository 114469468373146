.btn-primary {
  color: white;

    &:focus {
        color: white;
    }

    &:hover {
        color: white;
    }
}

.btn-outline-primary {
  &:focus {
      border-color: 'white';
      color: var(--primary);
      background-color: white;
  }

  &:active {
      border-color: var(--primary);
      color: white;
      background-color: white;
  }
}

.btn-outline-danger {
    &:focus {
        border-color: 'white';
        color: var(--danger);
        background-color: white;
    }
  
    &:active {
        border-color: var(--danger);
        color: white;
        background-color: white;
    }
  }