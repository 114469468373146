.accountInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}
.accountInfo img {
  border-radius: 50%;
}
.simpleButton {
  background-color: rgb(245, 245, 250);
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.25rem 1rem;
}

.general {
  padding: 0.5rem;
  border-bottom: 3px solid #ccc;
}
.tool {
  width: 100%;
  cursor: pointer;
}
.tool:hover {
  background-color: #ccc;
}
