@import "./custom.scss";

:root {
  --primary: #019f91;
  --secondary: #ee6a26;
  --white: #fff;
  --black: #000;
  --light-gray: #d3d3d3;
  --blue: rgb(0, 140, 255);
  --light-blue: rgb(0, 140, 255);
}

body {
  margin: 0;
  background-color: #ffffff !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}
