.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 0;
  border-radius: 50px;
  padding: 0.1rem;
  font-weight: bold;
}

.hotlineButton {
  position: fixed;
  bottom: 9rem;
  right: 1.3rem;
  z-index: 500;
  border-radius: 50px;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(17, 132, 180, 0.36);
  box-shadow: 0px 0px 10px 5px rgba(17, 132, 180, 0.36);
}
