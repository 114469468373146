.container {
  width: 95%;
  margin: 1rem auto;
}

.editorContainer {
  min-height: 90vh;
  margin: 1rem 0;
  padding: 1rem;
  background-color: white;
}

.formGroup {
  width: 100%;
}

.formLabel {
  display: block;
  width: 100%;
  margin: 0.5rem 0;
}

.formInput {
  display: block;
  width: 100%;
  padding: 0.5rem;
}

.submitButton {
  padding: 0.5rem 1rem;
  margin: 1rem 0;
}
