.container {
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0 5rem;
}

.navContainer {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.navItem {
  background-color: white;
  border: 1px solid var(--primary);
  border-radius: 5px;
  padding: 0.3rem 1rem;
  margin: 0;
  color: var(--primary);
}

.navItem span {
  font-weight: bold;
}

.navSelected {
  background-color: var(--primary);
}

.navSelected span {
  font-weight: bold;
}

.formGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formLabel {
  display: block;
  width: 100%;
  text-align: center;
  margin: 1rem 0;
}

.formInput {
  width: 100%;
  padding: 0.5rem 1rem;
}

.formButton {
  color: white;
  background-color: var(--primary);
  border-width: 0;
  padding: 0.2rem 1rem;
  border-radius: 5px;
  margin: 1rem 0;
}

.itemContainer {
  background-color: white;
  padding: 1rem;
  margin: 1rem 0;
}

.itemContainer p {
  text-align: center;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.buttonContainer button {
  background-color: var(--primary);
  color: white;
  border-width: 0;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  min-width: 5rem;
  margin: 1rem;
}

.itemContainer input {
  width: 100%;
}

.itemContainer textarea {
  width: 100%;
  margin: 1rem 0;
}
