.d-navbar {
  display: none;
}

@media screen and (min-width: 768px) {
  .m-navbar {
    display: none;
  }

  .d-navbar {
    display: block;
  }
}
