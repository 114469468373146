.buttonContainer {
  width: 8rem;
  margin: 0 0.4rem;
  border: 2px solid var(--primary);
  color: var(--primary);
  background-color: white;
  border-radius: 50px;
  padding: 0.4rem 1rem !important;
  font-weight: bold;
}

.selectedNav {
  /* background-color: rgb(0, 123, 255); */
  background-color: var(--primary);
  border-radius: 50px;
  color: white !important;
  font-weight: bold;
}

.unSelectedNav {
  border-radius: 50px;
  color: var(--primary) !important;
}

.navItem {
  margin: 0 0.4rem;
  padding: 0.4rem 1rem !important;
  border: 2px solid var(--primary);
}

.navItem:hover {
  background-color: var(--primary);
  border-radius: 50px;
  color: white !important;
}

.searchContainer {
  min-width: 20rem;
}

.dropDown {
  display: none;
  width: 80%;
  position: absolute;
  bottom: -2rem;
  background-color: #f9f9f9;
  z-index: 10;
}
/* .dropDownMenu:hover .dropDown {
  display: block;
} */
