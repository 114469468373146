.navIcon {
  width: 100%;
  color: var(--primary);
}

.navText {
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0;
}

.bottomNavContainer {
  box-shadow: rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 10px 10px 0 0;
}

.accountButton {
  background-color: transparent;
  border-width: 0;
}
